import React from "react"

import Layout from "../../../../components/layout-static"
import Seo from "../../../../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Connecticut Personal Injury Lawyers",
    description:
      "Looking for justice and maximum compensation for physical injuries or emotional trauma from abuse? Our firm fights for accountability and pursues the best outcome for victims. Contact us now.",
    heroH1: "Connecticut Personal Injury Lawyers",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>
              PERSONAL INJURY ATTORNEYS – WE PROVIDE A STRONG VOICE FOR THE
              PHYSICALLY AND EMOTIONALLY INJURED.
            </h2>
            <p className="mb-8">
              While some clients come to our firm suffering pain from physical
              injuries, others are dealing with emotional trauma from abuse.
              Regardless of the nature of their injuries, we dedicate all of the
              firm’s resources to not only maximize compensation, but also
              secure justice on their behalf. We fight for the people, not the
              powerful institutions.
            </p>
            <p className="mb-8">
              Catastrophic motor vehicle accidents and traumatic instances of
              nursing home neglect and inappropriate sexual contact have
              markedly different consequences. Our lawyers are committed to
              pursuing the best outcome for accident and abuse victims holding
              parties accountable.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <p className="mb-8">
              Our personal injury lawyers handle complex and emotionally charged
              legal issues that involve:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>
                <Link to="/car-accident-lawyer/">Automobile Accident</Link>
                <ul className="list-disc list-outside ml-12 mb-2">
                  <li>
                    Distracted Driver Accident, including cell phone accidents
                    and texting while driving accident cases leave loved ones
                    with serious injuries. Too often today, drivers use cell
                    phones and text while driving. When texting or cell phone
                    use leads to an accident, a distracted driver accident
                    attorney at Bartlett & Grippe can help you recover the
                    compensation you deserve.
                  </li>
                  <li>
                    Rear-end Collision Accident These seem straight forward, but
                    without an attorney insurance companies still try to
                    minimize the amount they pay. Having a knowledgeable
                    attorney on your side will help to ensure you receive the
                    recovery you deserve.
                  </li>
                  <li>
                    Hit and Run Accident, cases often leave victims with serious
                    injuries and questions about what can be done. By contacting
                    a hit and run accident attorney at our firm, you will learn
                    how your own insurance may provide you an avenue to recover
                    for your injuries. If you have been injured, you are not
                    alone, contact us today and let a hit and run accident
                    lawyer at our firm guide you on the road to the recovery you
                    deserve.
                  </li>
                  <li>Drunk Driver Accident</li>
                </ul>
              </li>
              <li>
                <Link to="/firm-overview/practice-areas/personal-injury/premises-liability/">
                  Slip and Fall Accident
                </Link>{" "}
                in Connecticut as the result of the negligence of another may
                entitle you to compensation for damages. Slip and fall accidents
                often require the use of experts to explain to juries the
                defective condition that caused the fall and why the condition
                should have been fixed by the property owner. We only get paid
                if we win on your behalf. Put a Connecticut Slip and Fall Lawyer
                to work for you by calling us now.
              </li>
              <li>
                Pedestrian Accident cases often involve serious injuries when a
                person walking or riding a bicycle is struck by a car or truck.
                This pedestrian accident and bicycle accident cases require
                knowledge of pertinent traffic laws and regulations. Some cases
                require the use of accident reconstructionists. Our firm has the
                knowledge, experience, and resources necessary to ensure that
                you get the compensation you deserve.
              </li>
              <li>
                <Link to="/firm-overview/practice-areas/personal-injury/premises-liability/">
                  Property Defect Accident
                </Link>{" "}
                One of Attorney Bartlett’s most rewarding results came after
                representing 52 clients in an apartment complex fire. Being able
                to help his clients get their lives back on the track made all
                the hours of hard work worthwhile. If you have suffered injuries
                as a result of a landlord or property owner’s failure to
                properly maintain their property, put a Cheshire property
                attorney to work for you by contacting us now.
              </li>
              <li>
                <Link to="/ct-wrongful-death/motorcycle-accidents/">
                  Motorcycle Accident
                </Link>
              </li>
              <li>
                <Link to="/ct-sex-abuse-attorney/child-sexual-abuse-attorney/">
                  Sexual Abuse
                </Link>{" "}
                Our Cheshire Connecticut firm has extensive experience with
                sexual abuse cases. We have handled many Boy Scout related
                sexual abuse cases in Connecticut. Attorney Bartlett was the
                lead counsel in one of the representative cases in the largest
                sexual abuse case in Connecticut history involving a hospital’s
                doctor and his patients. He has handled various sexual abuse
                cases such as sexual abuse by Boy Scout leaders, caregivers, and
                sexual abuse by foster parents.
              </li>
              <li>
                <Link to="/firm-overview/practice-areas/personal-injury/animal-bites/">
                  Animal bites Dog Bite / Animal Attack
                </Link>{" "}
                victims are often left with physical and emotional scars.
                Connecticut has laws that protect people from vicious dogs. When
                dog owners violate these laws, injuries can result. If you have
                suffered a dog bite or other injury as a result of an animal
                attack, call us today.
              </li>
              <li>
                <Link to="/ct-wrongful-death/connecticut-medical-malpractice/">
                  Medical Malpractice
                </Link>
              </li>
              <li>
                <Link to="/ct-wrongful-death/fire-liability-attorneys-ct/">
                  Fire Liability
                </Link>
              </li>
              <li>
                Bar Fight Injury or Dram shop liability lawsuits require unique
                knowledge of the laws associated with drinking alcohol in
                Connecticut. Attorney Bartlett has successfully argued many
                cases at all levels of the Connecticut court system dealing with
                alcohol-related personal injury suits. One of his bar injury
                related cases was televised and this video can be seen on our
                home page. Put this experience to work for you by calling us
                now.
              </li>
              <li>
                <Link to="/firm-overview/practice-areas/personal-injury/premises-liability/">
                  Premises Liability
                </Link>
              </li>
              <li>
                <Link to="/ct-wrongful-death/nursing-home-error-attorneys-ct/">
                  Nursing home neglect and abuse
                </Link>
              </li>
            </ul>
            <p className="mb-8">
              Facing powerful organizations and aggressive insurance companies
              without the help of an experienced attorney ignores their
              influence and resources. These institutions may claim to have the
              best interests of accident and abuse victims in mind. However,
              their goal is to minimize the negligent or deliberate acts that
              changed the lives of the firm’s clients and any related
              compensation.
            </p>
            <p className="mb-8">
              The firm’s objective is to pursue justice and maximum compensation
              for New Haven and Waterbury residents and those throughout the
              State of Connecticut. Even if that means going to trial.
            </p>
            <p className="mb-8">
              <strong>
                Clients facing an uncertain future should contact us now by{" "}
                <Link to="/contact-us/">
                  filling out the intake form or calling our personal injury
                  lawyers
                </Link>
                .
              </strong>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
